import './styles/app.scss';

import 'jquery';
import 'bootstrap';

// start the Stimulus application
//import './bootstrap';

window.addEventListener("DOMContentLoaded", () => {
    //
});